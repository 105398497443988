.landing-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color:#03071E ;
}

.landing-main .slider-body {
    position: relative;
    width: 100%;
    height: 800px;
}
.landing-main .slider-body .swiper-slide{
    position: relative;
    background-color: #03071E;
    width: 600px;
    height: 100%;
}
.landing-main .slider-body .swiper-slide img{
    width: 70%;
    height: 500px;
}