.mycart{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--main-background);
}
.cart-header{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 90%;
    margin: 35px auto;
}
.cart-header h1{
    font-size: 30px;
    font-family: var(--main-font-family);
    font-weight: 600;
    color: var(--main-color2);
    text-transform: capitalize;
}

.cart-line{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin: 25px auto;
    background-color: white;
}
.cart-line img{
    width: 200px;
    height: 200px;
    border-radius: 20px;
    margin: 15px;
}
.cart-line .line{
    font-size: 20px;
    font-family: var(--main-font-family);
    font-weight: 500;
    color: var(--main-color2);
    text-transform: capitalize;
    margin: auto 15px;
}
.cart-line button{
    font-size: 20px;
    font-weight: 500;
    color: var(--main-color2);
    margin: 13px;
}
.cart-line button a{
    text-decoration: none;
    color: #03071E;
}
@media screen and (max-width:350px){
    .cart-line img{
        width: 90%;
    }
}