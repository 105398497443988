.description-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 100px;
}
.menu-description{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin: 1%;
    width: 350px;
    border-radius: 10px;
}
.menu-description h1{
    text-transform: capitalize;

}
.menu-description p{
    text-transform: capitalize;
    
}