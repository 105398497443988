.footer {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: fit-content;
    background-color: #03071E;
}
.footer .main-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 20px;
    margin-left: 30px;
}
.footer .main-logo button{
    width: 150px;
    height: 70px;
    font-size: 35px;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}
.footer .main-logo button a{
    text-decoration: none;
    color: #FFBA08;
}
.footer .main-logo button:hover a{
    color: #03071E;
}
.footer .linkes{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.footer .linkes .home-link{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin: 15px auto;
    overflow: hidden;
    margin-left: 30px;
}
.footer .linkes .page-link{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin: 15px auto;
    overflow: hidden;
    margin-left: 30px;
}
.footer .linkes .main-link{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin: 15px auto;
    overflow: hidden;
    margin-left: 30px;
}
.footer .linkes .home-link h1,
.footer .linkes .page-link h1,
.footer .linkes .main-link h1{
    font-size: 30px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    color: white;
}
.footer .social-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 30px;
}
.footer .social-media h1{
    font-size: 25px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    color: #FFBA08;
    text-transform: capitalize;
}
.footer .social-media h2, h3{
    font-size: 20px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    color: #FFBA08;
    text-transform: capitalize;
}
.footer .linkes li {
    display: flex;
    align-items: center;
    text-align: center;
    width: 200px;
}

.footer .linkes a {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    width: 150px;
    height: 50px;
    padding: 15px;
    text-decoration: none;
    text-align: center;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    color: white;
    transition: var(--main-transition);
    overflow: hidden;
    margin-bottom: 15px;
}
.footer .linkes a::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    background-color: #FFBA08;
    bottom: 10px;
    left: -250px;
    transition: var(--main-transition);
}
.footer .linkes a:hover::after {
    left: 0;
}
.footer .linkes a:hover {
    color: #FFBA08;
}

.footer .subscrib {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
    padding: 5px 15px;
    margin: 5px auto;
}

.footer .subscrib button {
    width: 120px;
    height: 50px;
    color: #03071E;
    background-color: #F6C445;
    font-size: 20px;
    font-weight: 300;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    border: 0px solid;
    border-radius: 10px 0px 0px 10px;
    margin: 5px 0;
    padding: 5px;
    cursor: pointer;
}

.footer .subscrib .yemail {
    width: 280px;
    height: 50px;
    border: 1px solid #F6C445;
    border-radius: 0px 10px 10px 0px;
    color: #FFBA08;
    margin: 5px 0;

}

.footer .social-media i {
    font-size: 50px;
    font-weight: 700;
    color: #FFBA08;
    cursor: pointer;
}

.footer .social-media .fa-facebook {
    position: relative;
    width: 280px;
    height: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
}

.footer .social-media .fa-facebook::after {
    content: "facebook";
    position: absolute;
    font-size: 35px;
    font-weight: 800;
    font-weight: 300;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    text-align: center;
    align-items: center;
    width: 0px;
    height: 50%;
    overflow: hidden;
    z-index: -1;
    margin: auto;
    transition: var(--main-transition);
}

.footer .social-media .fa-facebook:hover::after {
    width: 90%;
    transition: var(--main-transition);
}

.footer .social-media .fa-linkedin {
    position: relative;
    width: 280px;
    height: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
}

.footer .social-media .fa-linkedin::after {
    content: "linkedin";
    position: absolute;
    font-size: 35px;
    font-weight: 800;
    font-weight: 300;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    text-align: center;
    align-items: center;
    width: 0px;
    height: 50%;
    overflow: hidden;
    z-index: -1;
    margin: auto;
    transition: var(--main-transition);
}

.footer .social-media .fa-linkedin:hover::after {
    width: 90%;
    transition: var(--main-transition);
}

.footer .social-media .fa-twitter {
    position: relative;
    width: 280px;
    height: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
}

.footer .social-media .fa-twitter::after {
    content: "twitter";
    position: absolute;
    font-size: 35px;
    font-weight: 800;
    font-weight: 300;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    text-align: center;
    align-items: center;
    width: 0px;
    height: 50%;
    overflow: hidden;
    z-index: -1;
    margin: auto;
    transition: var(--main-transition);
}

.footer .social-media .fa-twitter:hover::after {
    width: 90%;
    transition: var(--main-transition);
}

.footer .social-media .fa-youtube {
    position: relative;
    width: 280px;
    height: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    text-align: center;
}

.footer .social-media .fa-youtube::after {
    content: "youtube";
    position: absolute;
    font-size: 35px;
    font-weight: 800;
    font-weight: 300;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    text-align: center;
    align-items: center;
    width: 0px;
    height: 50%;
    overflow: hidden;
    z-index: -1;
    margin: auto;
    transition: var(--main-transition);
}

.footer .social-media .fa-youtube:hover::after {
    width: 90%;
    transition: var(--main-transition);
}

.footer .to-top{
    position: absolute;
    margin-top: 5px;
    margin-right: 5px;
    right: 0;
    top: 0;
}

.footer-buttom{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: #FFBA08 solid 1px;
}
.footer-buttom h1{
    font-size: 15px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    color: #FFBA08;
    margin-top: 5px;
    line-height: 1.5;
}