.main-sections{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: var(--main-background);
}
.main-sections h1{
    font-size: 35px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}
.main-sections p{
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}
.main-sections .section-1{
    display: flex;
    flex-direction: column;
    width: 90%;
    overflow: hidden;
}
.section-header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px 20px ;
    margin: 20px auto;
    width: 90%;
    border-radius: 15px;
    background: white;
    z-index: 2;
}
.section-header h1{
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 50px 20px;
    font-family: 'Lumanosimo', cursive;
}
.section-header .s-icons{
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 50px 20px;
    cursor: pointer;
}
.section-body{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.section-map-meal{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 400px;
    height: 600px;
    margin: 50px 30px;
    padding: 25px 20px;
    background-color: white;
    align-items: center;
    text-align: center;
    border-radius: 15px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
}
.section-map-meal img{
    width: 90%;
    height: 300px;
    border-radius: 25px;
}
.section-map-meal h2{
    font-size: 30px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}
.section-map-meal h3{
    font-size: 25px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}
.section-map-meal p{
    font-size: 18px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}