.booking-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.44);
    background-image: url(./images/table.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 100px auto;
}
.booking-main h1 {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
    color: #FFBA08;
    background-color: #03071E;
    text-align: center;
    width: 250px;
    height: 60px;
    padding: 10px 20px;
    border-radius: 15px;
    margin: 30px auto;
}

.booking-main .contact-in {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.booking-main .contact-in .contact-in-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
    padding: 10px;
}

.booking-main .contact-in .contact-in-1 .input-in {
    margin-bottom: 15px;
    width: 650px;
    margin: 10px auto;
    padding: 15px 20px;
    background-color: white;
    align-items: center;
    border-radius: 15px;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
    background: white;
    border: 0px solid;
    border-radius: 5px;
    box-shadow: 1px 5px 5px rgb(0, 0, 0 ,0.3);
}

.booking-main .contact-in .contact-in-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
    padding: 10px;
}

.booking-main .contact-in .contact-in-2 .input-in {
    margin-bottom: 15px;
    width: 690px;
    margin: 10px auto;
    padding: 15px 20px;
    background-color: white;
    align-items: center;
    border-radius: 15px;
    height: 220px;
    font-size: 20px;
    font-weight: 500;
    background: white;
    border: 0px solid;
    border-radius: 5px;
    box-shadow: 1px 5px 5px rgb(0, 0, 0 ,0.3);
}

.booking-main .contact-send {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.booking-main .contact-send button {
    width: 200px;
    height: 50px;
    color: #FFBA08;
    background-color: #03071E;
    font-size: 20px;
    font-weight: 300;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    border: 0px solid;
    border-radius: 10px;
    margin: 35px auto;
    padding: 10px;
    cursor: pointer;
}

@media screen and (max-width:800px) {
    .booking-main .contact-in .contact-in-1 {
        width: 100%;
    }

    .booking-main .contact-in .contact-in-2 {
        width: 100%;
    }

    .booking-main .contact-in .contact-in-1 .input-in {
        width: 90%;
        margin: 10px auto;
        padding: 10px 15px;
    }

    .booking-main .contact-in .contact-in-2 .input-in {
        width: 95%;
        margin: 10px auto;
    }
}