.girlled-menu-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #eee;
}
.head-title{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 50px auto;
}
.head-title i{
    font-size: 35px;
    font-weight: 500;
    margin: auto 25px;
}
.girlled-menu-items{
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url(../assets/girlled\ \(1\).jpg);
    background-position: center;
    background-size: cover;
    overflow: hidden;
}
.girlled-menu-item{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 400px;
        height: 600px;
        margin: 50px 30px;
        padding: 25px 20px;
        background-color: white;
        align-items: center;
        text-align: center;
        border-radius: 15px;
        transition: all 0.5s ease-in-out;
        overflow: hidden;
}

.girlled-menu-item img{
    width: 90%;
    height: 300px;
    border-radius: 25px;
}
.girlled-menu-item h1{
    font-size: 30px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}
.girlled-menu-item h3{
    font-size: 25px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}
.girlled-menu-item p{
    font-size: 18px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}
.girlled-menu-item .price{
    position: absolute;
    font-size: 20px;
    font-weight: 500;
    right: 15%;
    width: 25%;
    font-size: 17px;
}

@media screen and (max-width:850px){
    .girlled-menu-item{
        width: 90%;
    }
    .girlled-menu-item h1 {
            font-size: 15px;
            left: 5px;
            margin: 15px;
            width: 20%;
        }
        .girlled-menu-item p {
            font-size: 11px;
            margin: 5px;
        }
        .girlled-menu-item button {
            font-size: 12px;
            font-weight: 500;
        }

}