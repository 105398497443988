.grilled-review-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
}

.grilled-review-main h1{
    position: relative;
    display: flex;
    justify-content: center;
}
.grilled-review-main h1::before{
    content: "";
    position: absolute;
    width: 10%;
    height: 5px;
    background-color: #03071E;
    bottom:-15px;
}
.swiper {
    width: 100%;
    height:700px;
}

.grilled-review-customer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 400px;
    background: #03071E;
    border-radius: 20px;
}

.grilled-review-customer .g-review {
    position: absolute;
    left: 10%;
    top: 8%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin-bottom: 25px;
}
.grilled-review-customer .g-review img{
    width: 100px;
    height: 100px;
    border: 2px solid #FFBA08;
    border-radius: 50%;
    margin: 5px;
}
.grilled-review-customer .g-review h1{
    font-size: 30px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 10px;
    color: #FFBA08;
}
.grilled-review-customer .g-review-artical{
    position: relative;
    top: 40px;
    display: flex;
    justify-content: left;
    width: 65%;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    color: #FFBA08;
    margin-top: 25px;
}
.grilled-review-customer .g-review-artical .fa-quote-left{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    margin: -25px;
}
.grilled-review-customer .g-review-artical .fa-quote-right{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 30px;
    margin: -25px;
}

@media screen and (max-width:350px){
    .grilled-review-customer{
        width: 90%;
        height: 600px;
        border-radius: 20px;
    }
}