.food-slider-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-image {
    display: block;
    width: 500px;
    height: 450px;
    object-fit: cover;
}

@media screen and (max-width:500px){
    .food-slider-main{
        opacity: 0;
    }
}