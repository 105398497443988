.description-min{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #eee;
}
.description-body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.description-letter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 40%;
    height: 600px;
    padding: 20px 15px;
    margin: 35px 20px;
    text-align: left;
}
.description-letter h1{
    display: flex;
    font-size: 30px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}
.description-image{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 600px;
    padding: 20px 15px;
    margin: 35px 20px;
    background-color: #03071E;
}

.description-image img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 25px;
    right: 25px;
}

@media screen and (max-width:650px) {
    .description-letter {
        width: 80%;
    }

    .description-image {
        width: 80%;
    }
}