.services-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 100px auto;
}
.services-item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background: #ffb9083f;
    width: 450px;
    margin: 2%;
    transition: all 0.5s ease-in-out;
}
.services-item:hover{
    border: 1px solid #03071E;
    background-color: #FFBA08;
}
.services-item .icons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    margin: 25px;
    border-radius: 50%;
    text-align: center;
    background-color: #03071e72;
}
.services-item .icons i{
    font-size: 60px;
}
.services-item i{
    font-size: 20px;
    margin-left: 5px;
}
.services-item button{
    font-size: 20px;
    margin: 10px;
}
.services-item:hover .icons{
    background: #03071E;
}
.services-item:hover .icons i{
    color: #FFBA08;
}
.services-item h1{
    font-size: 30px;
    font-weight: 500;
}
.services-item p{
    font-size: 19px;
    font-weight: 500;
}