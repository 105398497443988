.gallary-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: white;
}
.head-title{
    display: flex;
    width: 100%;
}
.gallary-photo{
    padding: 10px;
    width: 100%;
}
.gallary-photo .gallary {
    display: block;
    overflow: hidden;
}
.gallary-photo .gallary img{
    width: 100%;
    display: block;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
    transition: all 0.5s ease-in-out ;
}
.gallary-photo .gallary img:hover{
    transform: scale(1.1);
    
}
