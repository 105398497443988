.review-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
}

.review-main h1{
    position: relative;
    display: flex;
    justify-content: center;
    text-transform: capitalize;
}
.review-main h1::before{
    content: "";
    position: absolute;
    width: 10%;
    height: 5px;
    background-color: #03071E;
    bottom: -15px;
}
.mySwiper {
    width: 90%;
    height: 100%;
}
.swiper-slide{
    width: 80%;
    height: 100%;
}
.swiper-slide img{
    width: 90%;
}