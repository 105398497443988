.blog-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: var(--main-background2);
}
.blog-title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.blog-title h1 {
    color: #03071E;
    font-size: 55px;
    font-weight: bolder;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}
.blog-body{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 1000px;
    margin: 25px auto;
    box-shadow: 5px 15px 15px rgb(0, 0, 0, 0.1);
}

.reviewCustomer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 500px;
    height: 600px;
    background-color: white;
}

.reviewCustomer .review {
    position: absolute;
    left: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

}

.reviewCustomer .review img {
    width: 100px;
    height: 100px;
    border: 2px solid #FFBA08;
    border-radius: 50%;
    margin: 5px;
}

.reviewCustomer .review h1 {
    font-size: 30px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 10px;
    color: #FFBA08;
}

.reviewCustomer .review-artical {
    position: relative;
    top: 120px;
    display: flex;
    justify-content: left;
    width: 65%;
    margin: 2%;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #FFBA08;
    margin-top: 25px;
}

.reviewCustomer .review-artical .fa-quote-left {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    margin: -25px;
}

.reviewCustomer .review-artical .fa-quote-right {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 30px;
    margin: -25px;
}

.gallary {
    display: block;
    overflow: hidden;
}

.gallary img {
    width: 500px;
    height: 600px;
    display: block;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
    transition: all 0.5s ease-in-out;
}

.gallary img:hover {
    transform: scale(1.1);
}

@media screen and (max-width:1000px){
    .blog-body{
        width: 90%;
        height: auto;
    }
    .gallary img{
        width: 100%;
    }
    .reviewCustomer{
        width: 100%;
    }
}