.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 10;
    height: 100px;
    margin-bottom: 100px;
    top: 0;
}

.header .logo {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100px;
    justify-content: flex-start;
    text-align: left;

}

.header .logo img {
    width: 70px;
    height: 60%;
}
.header .logo h1 {
    width: 70px;
    height: 50%;
    color: #FFBA08;
    font-family: 'Lumanosimo', cursive;
}

.header .links1 {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.header .links2 {
    display: none;
}
.header .links1 .navbar{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.links1 .links-main{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 130px;
    padding: 10px 20px;
    margin: 5px 15px;
}
.links1 .links-main .active-link{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #FFBA08;
    font-size: 20px;
    font-weight: 500;
    width: 130px;
    font-family: 'Lumanosimo', cursive;
    transition: var(--main-transition);
    cursor: pointer;
    text-transform: capitalize;
}
.links1 .links-main .active-link .cart-link{
    position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: #FFBA08;
        font-size: 20px;
        font-weight: 500;
        width: 150px;
        font-family: 'Lumanosimo', cursive;
        transition: var(--main-transition);
        cursor: pointer;
        text-transform: capitalize;
}
.count{
    font-size: 20px;
    font-weight: 500;
}
.links1 .links-main .active-link:hover
{
    background-color: #FFBA08;
    color: #03071E;
    border-radius: 25px;
}
.links1 .links-main .active-link .cart-link:hover
{
    background-color: #FFBA08;
    color: #03071E;
    border-radius: 25px;
}
.links1 .links-main .mylink-menu-active{
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color:#FFBA08;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    border-radius: 25px;
    top: 30px;
    left: 10px;
    width: 270px;
    height: fit-content;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 2;
    transition: 0.5s ease-in-out;
}
.links1 .links-main:hover .active-link{
    background-color: #FFBA08;
    color: #03071E;
    border-radius: 25px;
}
.links1 .links-main .mylink-menu-active p a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #03071E;
    font-size: 18px;
    font-weight: 500;
    padding: 0px 5px;
    width: 250px;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
    cursor: pointer;    
}
.links1 .links-main .mylink-menu-active p a:hover {
    border-radius: 25px;
    background-color: #03071E;
    color: #FFBA08;
} 
.links1 .links-main:hover .mylink-menu-active{
    transform: scaleY(1);
}
.links1 .links-main .active-link {
    text-decoration: none;
    color: #FFBA08;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    font-family: 'Lumanosimo', cursive;
    cursor: pointer;
}

@media screen and (max-width:850px) {
    .header .links2 {
        position: relative;
        display: flex;
        flex-direction:row;
        flex: 1;
        justify-content:right;
        align-items: center;
        transition: all 1s ease-in-out;
    }
    .header .links1{
        display: none;
    }
    .header .links2 .icons {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40px;
        opacity: 1;
        z-index: 2;
        background-color: #FFBA08;
        border-radius: 5px;
        top: -30px;
        margin-right: 20px;
        cursor: pointer;
    }
        .header .links2 .icons .icons-icon{
            font-size: 50px;
            font-weight: 600;
            color: #03071E;
        }
        .header .links2 .navbar{
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            top: 1px;
            width: 170px;
            height: auto;
            margin: 5px 20px;
            background-color: #FFBA08;
            border-radius: 10px;
            transition: var(--main-transition);
        }
        .header .links2 .navbar.inactive{
            opacity: 0;
            visibility: hidden;
            transform: translateX(180px);
            transition: var(--main-transition);
        }
        .header .links2 .navbar.active{
        opacity: 1;
        visibility: visible;
        height: auto;
        overflow: hidden;
        transform: translateX(0px);
        transition: var(--main-transition);
        }
        .header .links2 .navbar .smale-menu{
            position: relative;
            display: flex;
            flex-direction: column;
            height: auto;
            overflow: hidden;
        }
        .header .links2 .navbar .smale-menu .menu-link{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            z-index: 2;
            width: 170px;
            height: 50px;
            color: #03071E;
            font-size: 25px;
            font-weight: 400;
            font-family: 'Lumanosimo', cursive;
            text-transform: capitalize;
            z-index: 1;
            overflow: hidden;
        }
        .header .links2 .navbar .smale-menu .menu-link .icons-icon{
            text-align: center;
            margin-right: 10px;
            color: #03071E;
            width: 25px;
            height: 22px;
            cursor: pointer;
            font-size: 19px;
        }
        
        .header .links2 .navbar .smale-menu .menu-link-active{
            display: block;
            height: 0;
            overflow-y: hidden;
            transform-origin: top;
            transform: scaleY(0);
            transition: var(--main-transition);
        }
        .header .links2 .navbar .smale-menu .menu-link-active-home{
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #03071E;
            width: 170px;
            transform: scaleY(1);
            transform-origin: top;
            top: 50px;
            opacity: 1;
            z-index: 2;
            height: auto;
            overflow: hidden;
            transition: var(--main-transition);
        }
        
        .header .links2 .navbar .smale-menu .menu-link-active-home p{
            width: 95%;
            margin-top:7px;
            
        }
        .header .links2 .navbar .smale-menu .menu-link-active-home p a{
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            text-transform: capitalize;
            font-size: 20px;
            width: 100%;
            color: #FFBA08;
            cursor: pointer;
            transition: var(--main-transition);
        }
        .header .links2 .navbar .smale-menu .menu-link-active-home p a:hover{
            color: #03071E;
            background-color: #FFBA08;
            border-radius: 15px;
        }
        .header .links2 .navbar .menu-link .cart-link{
            color: #03071E;
            text-decoration: none;
            font-size: 25px;
            font-weight: 400;
            font-family: 'Lumanosimo', cursive;
            text-transform: capitalize;
        }
}

.header.sticky {
    padding: 1px 5px;
    height: 100px;
    background: #03071E;
    transform: translateY(1px);
    transform-origin:top;
    transition: var(--main-transition);
    transition-delay:0.2s;
}

.header.sticky .links1 ul li a,
.header.sticky .links2 ul li a {
    color: #FFBA08;
}
.header.sticky .links1 ul li a:hover,
.header.sticky .links2 ul li a:hover {
    color: #03071E;
}