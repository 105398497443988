.menu-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color:var(--main-background2);
    overflow-y: hidden;
    text-align: center;
    transform-origin: top;
    transition: var(--main-transition);
}

.menu-main .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
}

.menu-main .title h1 {
    margin: 50px auto;
    font-size: 55px;
    font-weight: bolder;
    font-family: 'Lumanosimo', cursive;
}

.menu-main .menu-item{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.menu-main .menu-item-body{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
    height: 600px;
    margin: 50px 20px;
    padding: 25px 10px;
    background-color: white;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 5px 15px 15px rgb(0, 0, 0, 0.1);
}

.menu-main  .menu-item-body .item-image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
}
.menu-main .menu-item-body .item-image img{
    width: 100%;
    height: 100%;
    border-radius: 25px;
}
.menu-main .menu-item-body .item-content{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items:center;
    text-align: left;
    z-index: 2;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    transition: all 0.5s ease-in-out;
}
.menu-main .menu-item-body .item-content h2{
    margin-top: 50px;
}
.menu-main .menu-item-body .item-content h2, 
.menu-main .menu-item-body .item-content h3{
    color: #FFBA08;
    font-size: 35px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}

.menu-main .menu-item-body .item-content p {
    color: #FFBA08;
    margin: 20px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}
.menu-main .menu-item-body .item-content button {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}
.menu-item-body:hover .item-content{
    background: rgb(0, 0, 0, 0.8);
    opacity: 1;
}

@media screen and (max-width:750px) {
    .sections .title h1 {
        font-size: 55px;
        font-weight: 700;
    }
}

@media screen and (max-width:350px) {
    .sections .title h1 {
        font-size: 35px;
        font-weight: 700;
    }

    .sections .section p {
        font-size: 15px;
        font-weight: 400;
    }

    .sections .section h1 {
        font-size: 25px;
        font-weight: 500;
    }
}