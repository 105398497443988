.offers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
}
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300%;
    background-size: cover;
    margin: 0;
    padding-top: 10%;
    padding-bottom: 1%;
}
.wrapper h1{
    font-size: 35px;
    font-weight: 700;
    margin: 30px auto;
    text-transform: capitalize;
}
.wrapper .wrapper-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.wrapper .wrapper-left ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.wrapper .wrapper-left ul li {
    display: flex;
    justify-content: center;
    text-align: center;
    list-style-type: none;
    font-size: 30px;
    font-weight: 500;
    border-radius: 5px;
    padding: 12px 25px;
    margin-bottom: 15px;
    width: 250px;
    height: 150px;
    margin: 0 15px;
    margin-bottom: 25px;
    position: relative;
    align-items: center;
    text-transform: capitalize;
    letter-spacing: 2px;
    background-color: var(--main-background2);
    font-family: 'Lumanosimo', cursive;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 5px 15px 15px rgb(0, 0, 0, 0.1);
    transition: var(--main-transition);
}

.wrapper .wrapper-left ul li:hover {
    transform: translateY(-10px);
}
.wrapper .wrapper-left ul li p{
    color: #03071E;
    font-size: 30px;
    font-weight: 700;
}

.wrapper-right {
    width: 100%;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper-right .sec {
    display: flex;
    width: 80%;
    margin: 50px 30px;
    padding: 25px 20px;
    margin: auto;
    align-items: center;
    text-align: center;
}

.wrapper-right .sec .sec-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #03071E;
    border-radius: 20px;
    transition: var(--main-transition);
}

.wrapper-right .sec .sec-info h1 {
    color: #FFBA08;
    font-size: 45px;
    font-weight: bolder;
    margin-bottom: 25px;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}

.wrapper-right .sec .sec-info img {
    width: 200px;
    height: 200px;
    border: 3px solid #FFBA08;
    border-radius: 50%;
    
}

.wrapper-right .sec .sec-info p {
    color: #FFBA08;
    font-size: 25px;
    font-weight: bolder;
    height: auto;
    margin: 75px auto;
    text-transform: capitalize;
}

.wrapper-right .sec .sec-info button {
    display: flex;
    align-items: center;
    border: 0px solid;
    border-radius: 25px;
    margin-bottom: 25px;
    background-color: #FFBA08;
    margin: 20px;
}

.wrapper-right .sec .sec-info button a {
    color: #03071E;
    text-decoration: none;
    font-size: 15px;
}

@media screen and (max-width:850px) {
    .wrapper-right .sec .sec-info h1 {
        font-size: 30px;
        font-weight: 600;
    }

    .wrapper-right .sec .sec-info p {
        font-size: 20px;
        font-weight: 500;
        margin: auto;
    }
}

@media screen and (max-width:450px) {
    .wrapper-right .sec .sec-info h1 {
        font-size: 25px;
        font-weight: 400;
    }

    .wrapper-right .sec .sec-info p {
        font-size: 15px;
        font-weight: 300;
        margin: auto;
    }
}