.gallary-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: white;
}

.head-title {
    display: flex;
    width: 100%;
}

.gallary-photo {
    padding: 10px;
    width: 100%;
}

.gallary-photo .gallary {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    overflow: hidden;
}

.gallary-photo .gallary .gallary-title {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    z-index: 2;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
}

.gallary-photo .gallary .gallary-title h1 {
    color: #FFBA08;
    margin-bottom: 25px;
    font-family: 'Lumanosimo', cursive;
    transition: all 1s ease-in-out;
    transition-delay: 0.5s;
    transform: translateY(500px);
}

.gallary-photo .gallary .gallary-image {
    width: 100%;
    height: 100%;
}

.gallary-photo .gallary .gallary-image img {
    width: 100%;
    height: 100%;
    display: block;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
}

.gallary-photo .gallary:hover .gallary-title {
    background: rgb(0, 0, 0, 0.8);
    opacity: 1;
}

.gallary-photo .gallary:hover .gallary-title h1 {
    transform: translateY(0px);
}