.meals-swiper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: #03071E;
}
.meals-swiper h1 {
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 10px auto;
    color: #FFBA08;
    font-family: 'Lumanosimo', cursive;
}


.mySwiper {
    width: 90%;
    height: 500px;
    margin: 50px auto;
}
 
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    width: 500px;
}

.swiper-slide img {
    display: flex;
    justify-content: flex-start;
    width: 40%;
    height: 80%;
    border: 0px solid #03071E;
    border-radius: 15px;
}
.swiper-slide .swiper-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.swiper-slide .swiper-body h1{
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
    color: #03071E;
    font-family: 'Lumanosimo', cursive;
}
.swiper-slide .swiper-body p{
    font-size: 19px;
    font-weight: 600;
    font-family: 'Lumanosimo', cursive;
}