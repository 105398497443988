.main-girlled-landing{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 900px;
    background:#03071E ;
    overflow: hidden;
}

.main-girlled-landing .fa-arrow-left{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin-left:100px;
    bottom: 0;
    margin-bottom: 15px;
    font-size: 25px;
    width: 70px;
    height: 50px;
    color: #03071E;
    background-color: #FFBA08;
    cursor: pointer;
}
.main-girlled-landing .fa-arrow-right{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin-right: 100px;
    margin-bottom: 15px;
    bottom: 0;
    font-size: 25px;
    width: 70px;
    height: 50px;
    color: #03071E;
    background-color: #FFBA08;
    cursor: pointer;
}
.main-girlled-landing .fa-arrow-right:hover,
.main-girlled-landing .fa-arrow-left:hover{
    color: #FFBA08;
    background-color:black ;
}
.slide{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    margin: 25px 10px;
}
.active{
    opacity: 1;
    transform: translateX(0);
}
.slide img{
    position: absolute;
    right:70px ;
    top: 100px;
    width: 68%;
    height: 80%;
    opacity: 0;
    border-radius: 25px;
    z-index: -1;
}
.content{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    border-radius: 25px;
    top: -19rem;
    visibility: hidden;
    opacity: 0;
    left: 10%;
    width: 40%;
    height: 50%;
    padding: 26px;
    transition:3s ease-in-out;
    transition-delay: 1s;
    animation: slide-up 1s ease .5s;
    animation-fill-mode: forwards;
    
}
@keyframes slide-up{
    0%{
        visibility: hidden;
        opacity: 0;
        top: -19rem;
    }
    100%{
        visibility: visible;
        opacity: 1;
        top: 3rem;
    }
}
.content h2{
    font-size: 35px;
    font-weight: 500;
    color: #FFBA08;
    margin-bottom: 25px;
    font-family: 'Lumanosimo', cursive;
}
.content h3{
    font-size: 15px;
    font-weight: 500;
    color: #FFBA08;
    margin-bottom: 25px;
    font-family: 'Lumanosimo', cursive;
}
.content button{
    width: 150px;
    font-size: 18px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    font-family: 'Lumanosimo', cursive;
}
.slide.active .content{
    opacity: 1;
}
.slide.active img{
    opacity: 1;
}