.main-teem{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.team-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.team-title h1 {
    color: #03071E;
    font-size: 55px;
    font-weight: bolder;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}
.teem-white{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 1000px;
    margin: 25px auto;
    box-shadow: 5px 15px 15px rgb(0, 0, 0, 0.1);
}
.teem-offwhite{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 1000px;
    margin: 25px auto;
    background-color: var(--main-background2);
    box-shadow: 5px 15px 15px rgb(0, 0, 0, 0.1);
}
.teem-white img,
.teem-offwhite img{
    width: 400px;
    height: 600px;
    border-radius: 10px;
    margin: 10px;
}
.teem-let{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:left;
    text-align: left;
    width: 500px;
    margin: 25px;
    padding: 15px;
    line-height: 1.5;
}
.reviews i{
    color: #FFBA08;
}
@media screen and (max-width:1000px){
    .teem-offwhite{
        width: 80%;
    }
    .teem-white{
        width: 80%;
    }
        .teem-white img,
        .teem-offwhite img {
            width: 100%;
            height: 500px;
        }
}