.main-nonepage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
    margin: 70px auto;
}
.main-nonepage h1{
    font-size: 30px;
    font-weight: 500;
}
.main-nonepage p{
    font-size: 20px;
    font-weight: 400;
}

.main-nonepage button a{
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    color: #03071E;
}