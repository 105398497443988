.review-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
}

.review-main h1{
    position: relative;
    display: flex;
    justify-content: center;
}
.review-main h1::before{
    content: "";
    position: absolute;
    width: 10%;
    height: 5px;
    background-color: #03071E;
    bottom: -15px;
}
.swiper {
    width: 100%;
    height: 100%;
}

.desserts-review-customer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 400px;
    background: #03071E;
    border-radius: 20px;
}

.desserts-review-customer .review {
    position: absolute;
    left: 10%;
    top: 8%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin-bottom: 25px;
    
}
.desserts-review-customer .review img{
    width: 100px;
    height: 100px;
    border: 2px solid #FFBA08;
    border-radius: 50%;
    margin: 5px;
}
.desserts-review-customer .review h1{
    font-size: 30px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 10px;
    color: #FFBA08;
}
.desserts-review-customer .review-artical{
    position: relative;
    top: 40px;
    display: flex;
    justify-content: left;
    width: 65%;
    margin: 2%;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #FFBA08;
    margin-top: 25px;
}
.desserts-review-customer .review-artical .fa-quote-left{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    margin: -25px;
}
.desserts-review-customer .review-artical .fa-quote-right{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 30px;
    margin: -25px;
}

@media screen and (max-width:750px) {
    .desserts-review-customer {
        width: 80%;
        height: 600px;
    }
}
@media screen and (max-width:350px) {
    .desserts-review-customer {
        width: 90%;
        height: 600px;
    }
}