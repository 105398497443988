.mlanding-main{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
    overflow: hidden;
}
.mlanding-main .m-intro{
    background: url(./images/big\ meal\ \(4\).jpg) fixed 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 300%;
    padding-top: 10%;
    padding-bottom: 1%;
    margin-top: 0px;
}
.mlanding-main .m-intro h1{
    font-size: 30px;
    font-weight: 900;
    font-family: 'Lumanosimo', cursive;
    color: #03071E;
}
.mlanding-main .m-intro .m-intro-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color:#03071E ;
    width: 249px;
    height: 300px;
    border-radius: 20px;
}
.mlanding-main .m-intro .m-intro-body h1,h2,h3{
    color: #FFBA08;
    font-size: 23px;
    font-weight: 900;
    font-family: 'Lumanosimo', cursive;
}