.App {
  text-align: center;
  overflow: hidden;
}

.res_ani_section{
  --main-transition: 0.5s ease-in-out;
  --main-font-family: 'Lumanosimo', cursive;
  --main-color2: #03071E;
  --main-background: #f6faff !important;
  --main-color: #FFBA08;
}