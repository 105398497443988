.desserts-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: var(--main-background);
}
.desserts-main h1{
    font-size: 35px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}
.desserts-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform-origin: top;
    transition: 0.5s ease-in-out;
}
.section-map-meal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 400px;
    height: 600px;
    margin: 50px 30px;
    padding: 25px 20px;
    background-color: white;
    align-items: center;
    text-align: center;
    border-radius: 15px;
}

.section-map-meal img {
    width: 90%;
    height: 300px;
    border-radius: 25px;
}

.section-map-meal h2 {
    font-size: 30px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}

.section-map-meal h3 {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}

.section-map-meal p {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}

@media screen and (max-width:350px) {
    .section-map-meal {
        width: 290px;
    }
}