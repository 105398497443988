.team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: white;
}

.team .team-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.team .team-title h1 {
    color: black;
    font-size: 55px;
    font-weight: bolder;
    font-family: 'Lumanosimo', cursive;
    text-transform: capitalize;
}

.team .team-pro {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

.team .team-pro .team-prof {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    height: 500px;
    margin: 50px 30px;
    padding: 25px 20px;
    background-color: white;
    align-items: center;
    text-align: center;
    border-radius: 15px;
    transition: var(--main-transition);
}

.team .team-pro .team-prof img {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    border: 7px solid #03071E;
}

.team .team-pro .team-prof h1 {
    font-size: 35px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}

.team .team-pro .team-prof p {
    font-size: 25px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}

.team .team-pro .team-prof .icon-social {
    display: flex;
    flex-direction: row;
}

.team .team-pro .team-prof .icon-social i {
    font-size: 35px;
    font-weight: 900;
    margin: auto 15px;
    cursor: pointer;
    color: #03071E;
    transition: var(--main-transition);
}

.team .team-pro .team-prof .icon-social i:hover {
    color: #FFBA08;
    margin-top: -5px;
}